<template>
	<div style="position: relative;min-height: 100vh;background-color:white;" class="box1 flex-column-center">
		<div class="top">
			<img src="https://khome2.tuzuu.com/vita/consul.png">
			<!--时间-->
			<div style="position: absolute;top: 20px;width: 100%;height: 100%" class="flex-column-center">
				<div class="time flex">
					请在
					<van-count-down :time="time" />
					内完成报名激活，开启健康之旅！
				</div>
				<div class="title flex-column-center">
					<img src="https://khome2.tuzuu.com/vita/icon_3.png">
					恭喜报名成功
				</div>
				<!--<div class="top-end">-->
				<!--	最后一步-->
				<!--</div>-->
			</div>

		</div>
		<div class="bottom">
			<div class="title">
				<p>长按扫码，添加专属营养顾问</p>
				<p>定制个人健康改善计划</p>
			</div>
			<div class="flex-center">
				<van-image width="144" :src="data.qrcode" @load="createPoster" />
			</div>
			<div class="info">
				<p>你的专属营养顾问</p>
				<p>{{ data.name }}</p>
			</div>
			<div class="end-info">
				因微信每日添加人数限制，营养顾问会尽快通过申请
				让我们开启健康的每一天吧
			</div>
		</div>
		<img :src="url" class="poster">
	</div>
</template>

<script>
	import html2canvas from "html2canvas"
	import {
		Toast
	} from "_vant@2.12.22@vant";

	export default {
		name: "",
		data() {
			return {
				time: 24 * 60 * 60 * 1000,
				data: {},
				url: ''
			}
		},
		created() {
			let id = this.$route.query.adviser_id
			this.$request.get(
				"get_adviser_qrcode", {
					adviser_id: id || 0
				}
			).then(res => {
				if (res.code == 1) {
					this.data = res.data
					//开始倒计时,如果本地存在
					if (window.localStorage.getItem("timer")) {
						let overDate = window.localStorage.getItem("timer")
						this.time = overDate - new Date().getTime()
						//如果时间到期，重新存储
						if (this.time < 0) {
							window.localStorage.removeItem("timer")
							let overDate = new Date().getTime() + 24 * 60 * 60 * 1000;
							window.localStorage.setItem("timer", overDate)
							this.time = overDate - new Date().getTime()
						}
					} else {
						let overDate = new Date().getTime() + 24 * 60 * 60 * 1000;
						window.localStorage.setItem("timer", overDate)
						this.time = overDate - new Date().getTime()
					}
				} else {
					Toast(res.message)
				}
			})
		},
		methods: {
			createPoster() {
				setTimeout(() => {
					new html2canvas(document.querySelector(".box1"), {
						allowTaint: false,
						useCORS: true,
						backgroundColor: null,
						// scale:2,
						dpi: 300
					}).then(canvas => {
						this.url = canvas.toDataURL("image/png")
						console.log('生成');
					})
				}, 100)
			},
		}
	}
</script>

<style scoped lang="less">
	.top {
		width: 100%;
		position: relative;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.time {
			font-size: 16px;
			color: #FFFFFF;

			.van-count-down {
				font-size: 16px;
				color: #FFFFFF;
				margin: 0px 7px;
				display: contents;
			}
		}

		.title {
			font-weight: 700;
			font-size: 18px;
			color: #FFFFFF;
			margin-top: 20px;

			>img {
				width: 35px;
				margin-bottom: 10px;
			}
		}

		//.top-end {
		//	font-size: 21px;
		//	color: #292C2F;
		//	font-weight: 700;
		//	text-align: center;
		//	position: absolute;
		//	bottom: 30px;
        //
		//}
	}

	.bottom {
		width: 100%;
		//margin-top: 20px;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.title {
			font-size: 20px;
			font-weight: 700;
			text-align: center;
			line-height: 30px;
			//margin-top: 10px;
			color: #292C2F;
			margin-bottom: 10px;
		}

		.info {
			font-size: 16px;
			font-weight: 500;
			color: #292C2F;
			line-height: 25px;
			text-align: center;
			margin-top: 15px;
		}

		.end-info {
			font-size: 14px;
			color: #666666;
			position: absolute;
			bottom: 25px;
			left: 23px;
			line-height: 20px;
			right: 23px;
			text-align: center;
		}
	}

	.poster {
		position: absolute;
		top: 0;
		opacity: 0;
		z-index: 2;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>
